import React from 'react';
import { action } from 'mobx'
import { observer } from 'mobx-react'
import axios from 'axios'


import state from '../../store/state'
import './auth.css'
import Button from '../../components/button/button';
import Loader from '../../components/loader/loader';
import Input from '../../components/input/input';

const Auth = () => {

    const { login, password } = state.inputs

    const updateState = action(data => {
        state.auth = data.auth
        state.auth_error = data.error
    })

    const set_auth_error_message = action(text => {
        state.auth_error_message = text
    })

    const loader = action(e => {
        state.loader = e
    })


    const onClick = (e) => {
        console.log('gg', state.inputs.login.value, state.inputs.login.value.length, state.auth_error_message);
        e.preventDefault()
        if (state.inputs.login.value.length < 1 || state.inputs.password.value.length < 1) {
            set_auth_error_message('заполните оба поля')
        } else {
            set_auth_error_message('')
            loader(true)
            axios.post(`${state.base_url}/api/v2/data.php`, { login: login.value, password: password.value }, { withCredentials: true }).then(({ data }) => {
                updateState(data)
                loader(false)
            }).catch(() => {
                loader(false)
                set_auth_error_message('Упс.... всё плохо(((')
            })
        }
    }

    return (
        state.loader ?
            <Loader /> :
            <div className='parent'>
                <form>
                    <div className="form-group text-center">
                        {state.auth_error}
                    </div>
                    <div className="form-group text-center">
                        {state.auth_error_message}
                    </div>
                    <div className="form-group">
                        <Input
                            type='login'
                            className="form-control auth_input"
                            name='login'
                            value={login.value}
                            placeholder='логин'
                            group='auth'
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            type='password'
                            className="form-control auth_input"
                            name='password'
                            value={password.value}
                            placeholder='пароль'
                            autoComplete='false'
                            group='auth'
                        />
                    </div>
                    <Button
                        className='btn btn-primary btn-block'
                        text='войти'
                        onClick={onClick}
                    />
                </form>
            </div>
    )
}

export default observer(Auth);