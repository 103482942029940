import React from 'react';
import Table from '../../components/table/table';
import './home.css'

const Home = () => {
    return (
        <div className='home_wrapper'>
            <Table />
        </div>
    )
}
export default Home