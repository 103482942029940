import React from 'react';
//import { action } from 'mobx'
import { observer } from 'mobx-react'
//import axios from 'axios'

import Input from '../input/input'
import state from '../../store/state'

const AddInstr = () => {


    return (
        <tr>
            <td></td>
            <td>
                <Input
                    type='text'
                    name='number_kvit'
                    value={state.inputs.number_kvit.value}
                    group='add_instr'
                    className="form-control form-control-sm"
                />
            </td>
            <td>
                <Input
                    type='text'
                    name='instr_name'
                    value={state.inputs.instr_name.value}
                    group='add_instr'
                    className="form-control form-control-sm"
                />
            </td>
            <td>
                <Input
                    type='text'
                    name='instr_gruppa'
                    value={state.inputs.instr_gruppa.value}
                    group='add_instr'
                    className="form-control form-control-sm"
                />
            </td>
            <td>
                <Input
                    type='text'
                    name='instr_cod'
                    value={state.inputs.instr_cod.value}
                    group='add_instr'
                    className="form-control form-control-sm"
                />
            </td>
            <td>
                <Input
                    type='text'
                    name='name_z'
                    value={state.inputs.name_z.value}
                    group='add_instr'
                    className="form-control form-control-sm"
                />
            </td>
            <td>
                <Input
                    type='text'
                    name='art_z'
                    value={state.inputs.art_z.value}
                    group='add_instr'
                    className="form-control form-control-sm"
                />
            </td>
            <td>
                <Input
                    type='text'
                    name='cod_z'
                    value={state.inputs.cod_z.value}
                    group='add_instr'
                    className="form-control form-control-sm"
                />
            </td>
            <td>
                <Input
                    type='text'
                    name='instr_komment'
                    value={state.inputs.instr_komment.value}
                    group='add_instr'
                    className="form-control form-control-sm"
                />
            </td>
        </tr>
    )
}

export default observer(AddInstr);