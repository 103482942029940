import React, { useEffect } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import axios from 'axios'

import './table.css'
import state from '../../store/state'
import Tbody from './tbody/tbody';

const Table = () => {

    const updateState = action(data => {
        state.group_instr_table = data
    })

    useEffect(() => {
        axios.get(`${state.base_url}/api/v2/table.php?group=1`, { withCredentials: true }).then(({ data }) => {
            updateState(data)
        }).catch(() => {
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <table className='table table-bordered table-sm table_instr'>{
            state.group_instr_table ?
                <Tbody data={state.group_instr_table} /> : ''}

        </table>
    )
}
export default observer(Table)