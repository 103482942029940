import React, { useEffect } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import axios from 'axios'

import './App.css';
import state from './store/state'
import Auth from './pages/auth/auth';
import Home from './pages/home/home';

const App = () => {

  const updateState = action(data => {
    state.auth = data.auth
    state.loader = false
  })

  const loader = action(e => {
    state.loader = e
  })

  useEffect(() => {
    axios.get(`${state.base_url}/api/v2/auth.php`, { withCredentials: true }).then(({ data }) => {
      updateState(data)
    }).catch(err => {
      loader(false)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    state.auth ?
      <Home /> :
      <Auth />
  );
}

export default observer(App);
