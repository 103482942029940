
import { makeAutoObservable } from 'mobx'

const state = {
    group_instr_table: [],
    base_url: 'https://z.service-kalibrcompany.ru',
    inputs: {
        login:
        {
            value: ''
        },
        password:
        {
            value: ''
        },
        number_kvit:
        {
            value: ''
        },
        instr_name:
        {
            value: ''
        },
        instr_gruppa:
        {
            value: ''
        },
        instr_cod:
        {
            value: ''
        },
        name_z:
        {
            value: ''
        },
        art_z:
        {
            value: ''
        },
        cod_z:
        {
            value: ''
        },
        instr_komment:
        {
            value: ''
        },
        search_inputs:
        {
            type: 'text',
            name: 'instr_gruppa',
            group: 'search_inputs',
            className: "form-control form-control-sm",
            value: '',
            search: false,
            searched: true

        },
    },
    buttons: {
        add_instr: true,
        add_instr_text: 'добавить'
    },
    auth: false,
    auth_error_message: null,
    loader: true,
    add_instr_hide: true
}

export default new makeAutoObservable(state)