import React from 'react';
import { action } from 'mobx'
import { observer } from 'mobx-react'

import state from '../../store/state'
import './input.css'

const Input = (props) => {

    const updateState = action((name, value) => {
        state.inputs[name].value = value
        if (state.inputs[name].searched && value.length > 0) {
            state.inputs[name].search = true
        } else if (state.inputs[name].searched && value.length < 1) {
            state.inputs[name].search = false
        }
    })

    const onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        updateState(name, value)
    }
    return (
        <input
            type={props.type}
            className={props.className}
            name={props.name}
            value={props.value}
            onChange={onChange}
            autoComplete={props.autoComplete}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            onDoubleClick={props.onDoubleClick}
        />
    )
}

export default observer(Input);