import React from 'react';
import { action } from 'mobx'
import { observer } from 'mobx-react'


import state from '../../../store/state'

import Input from '../../input/input'
import Button from '../../button/button';
import './tbody.css'
import AddInstr from '../../add_instr/add_instr';


const Tbody = (props) => {

    const onClick = (e) => {
        const id = e.target.id
        console.log(id);
    }

    const addInstrSave = () => {

    }

    const addInstr = action(() => {
        state.add_instr_hide = !state.add_instr_hide
        state.buttons.add_instr = !state.buttons.add_instr
        state.buttons.add_instr ?
            state.buttons.add_instr_text = 'добавить' :
            state.buttons.add_instr_text = 'отмена'
    })

    const state_group_instr_table_filter = props.data.filter(item => {
        const { search_inputs } = state.inputs
        const query = search_inputs.value.toLowerCase();
        return (
            item.number_kvit.toLowerCase().indexOf(query) !== -1 ||
            item.instr_name.toLowerCase().indexOf(query) !== -1 ||
            item.instr_gruppa.toLowerCase().indexOf(query) !== -1 ||
            item.instr_cod.toLowerCase().indexOf(query) !== -1 ||
            item.name_z.toLowerCase().indexOf(query) !== -1 ||
            item.art_z.toLowerCase().indexOf(query) !== -1 ||
            item.cod_z.toLowerCase().indexOf(query) !== -1 ||
            item.z_komment.toLowerCase().indexOf(query) !== -1
        )

    });
    const onDoubleClick = (e) => {
        const id = e.target.id
        const el = document.getElementById(id)
        const value = el.innerHTML
        el.innerHTML = '<textarea rows="1" class="input_read" id="hhh" />'
        const textarea = document.getElementById('hhh')
        textarea.value = value
        textarea.focus()
        textarea.onblur = () => {
            el.innerHTML = value
        }
    }
    return (
        <>
            <thead>
                <tr>
                    <th colSpan='8'>
                        <Button
                            className='btn btn-outline-primary'
                            text={state.buttons.add_instr_text}
                            onClick={addInstr}
                        />
                        {
                            !state.add_instr_hide ?
                                <Button
                                    className='btn btn-outline-success add_instr_btn_save'
                                    text='сохранить'
                                    onClick={addInstrSave}
                                /> : null
                        }
                    </th>
                    <th>
                        <Input
                            type='text'
                            name='search_inputs'
                            value={state.inputs.search_inputs.value}
                            className="form-control form-control-sm ggg"
                            placeholder='поиск по всей таблице'
                        />
                    </th>
                </tr>
                {
                    !state.add_instr_hide ?
                        <AddInstr /> : null
                }
                <tr className='text-center'>
                    <th></th>
                    <th>№ квит</th>
                    <th>наименование инструмента</th>
                    <th>завод</th>
                    <th>код</th>
                    <th>запчасть</th>
                    <th>артикул</th>
                    <th>код</th>
                    <th>комментарий</th>
                </tr>
            </thead>
            <tbody className='table-striped'>
                {state_group_instr_table_filter.map(item => (
                    <tr key={item.id}>
                        <td className='td'>
                            <div>
                                <i
                                    onClick={onClick}
                                    className="fa fa-plus kkk"
                                    aria-hidden="true"
                                />
                            </div>
                        </td>

                        <td>{item.number_kvit}</td>
                        <td>{item.instr_name}</td>
                        <td>{item.instr_gruppa}</td>
                        <td>{item.instr_cod}</td>
                        <td>{item.name_z}</td>
                        <td>{item.art_z}</td>
                        <td>{item.cod_z}</td>
                        <td
                            id={item.id}
                            onDoubleClick={onDoubleClick}
                        >
                            {
                                item.z_komment
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </>
    )
}
export default observer(Tbody)